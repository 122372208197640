import React from 'react';
import { GALLERY_CONSTS } from 'pro-gallery';
import { utils } from '../../utils/webUtils';
import { experimentsWrapper } from '@wix/photography-client-lib/dist/src/sdk/experimentsWrapper';
import {
  EXTERNAL_INFO_TYPE,
  TextInfoElement,
} from '@wix/pro-gallery-info-element';

export default class ItemsHelper {
  constructor(galleryWrapper, props, isStoreGallery) {
    this.galleryWrapper = galleryWrapper;
    this.galleryWrapperProps = props;
    this.isStoreGallery = isStoreGallery;
    this.update = this.update.bind(this);
    this.pgItemsProps = this.pgItemsProps.bind(this);
    this.areOneOrMoreItemsCorrupted = this.areOneOrMoreItemsCorrupted.bind(
      this,
    );
    this.getMoreItems = this.getMoreItems.bind(this);
    this.processPgItemPropsForInfoElement = this.processPgItemPropsForInfoElement.bind(
      this,
    );
    this.getExternalInfoRenderersIfNeeded = this.getExternalInfoRenderersIfNeeded.bind(
      this,
    );

    this.getMoreItemsCalled = false;
  }

  update(props) {
    this.galleryWrapperProps = props;
  }

  pgItemsProps(pgStyles) {
    let items;

    if (!this.galleryWrapperProps.wixCodeItems) {
      items =
        this.galleryWrapper.state.manualItems ||
        this.galleryWrapperProps.items ||
        [];
    } else {
      items = this.galleryWrapperProps.wixCodeItems.map((item) => {
        if (typeof item.metaData === 'string') {
          try {
            const newItem = {
              ...item,
              metaData: JSON.parse(item.metaData),
            };
            return newItem;
          } catch (e) {
            console.error('Failed parse item metaData', e);
          }
        }
        return item;
      });
    }
    const shouldEstimateInitialItems =
      experimentsWrapper.getExperimentBoolean(
        'specs.pro-gallery.estimateInitialItems',
      ) &&
      pgStyles &&
      !this.getMoreItemsCalled &&
      (this.galleryWrapper.viewMode === GALLERY_CONSTS.viewMode.SITE ||
        this.galleryWrapper.viewMode === GALLERY_CONSTS.viewMode.SEO);
    if (shouldEstimateInitialItems) {
      const initialItemsBatchSize = this.getInitialItemsEstimation(pgStyles);
      items = items.slice(0, initialItemsBatchSize - 1);
      if (utils.isVerbose()) {
        console.log(
          'Using estimated batch size for initial items: ',
          initialItemsBatchSize,
        );
      }
    }
    const totalItemsCount =
      this.galleryWrapperProps.totalItemsCount || items.length || 1;

    return {
      items,
      totalItemsCount,
    };
  }

  areOneOrMoreItemsCorrupted(items) {
    return items.some(this.isInvalidItem);
  }

  isInvalidItem(item) {
    // for future validations add more conditions
    const containsItemId = item.itemId === undefined;
    return containsItemId;
  }

  getMoreItems(from) {
    if (this.galleryWrapperProps.getMoreItems) {
      this.getMoreItemsCalled = true;
      this.galleryWrapperProps.getMoreItems(from);
    }
  }

  getInitialItemsEstimation(pgStyles) {
    const min = 5;
    const max = 50;
    let numOfCols = 1;
    let numOfRows = 1;
    const isMobile =
      this.galleryWrapper.formFactor === GALLERY_CONSTS.formFactor.MOBILE;
    switch (pgStyles.galleryLayout) {
      // Adaptive layouts / Vertical only layouts
      case -1: // EMPTY: -1
      case 1: // MASONRY: 1
      case 6: // PANORAMA: 6
      case 8: // MAGIC: 8
      case 10: // BRICKS: 10
      case 11: // MIX: 11
      case 12: // ALTERNATE: 12
        // pgStyles.scrollDirection = GALLERY_CONSTS.scrollDirection.VERTICAL;
        numOfRows = 4;
        numOfCols =
          (pgStyles.gridStyle !== 0 && pgStyles.numberOfImagesPerRow) ||
          (isMobile ? 1 : 5);
        break;
      case 0: // COLLAGE: 0,
      case 2: // GRID: 2,
        const scrollDirection =
          pgStyles.scrollDirection === undefined
            ? GALLERY_CONSTS.scrollDirection.VERTICAL
            : pgStyles.scrollDirection;
        if (scrollDirection === GALLERY_CONSTS.scrollDirection.VERTICAL) {
          numOfRows = 5;
          numOfCols =
            (pgStyles.gridStyle !== 0 && pgStyles.numberOfImagesPerRow) ||
            (isMobile ? 1 : 5);
        } else {
          numOfRows = pgStyles.numberOfImagesPerCol || 4;
          numOfCols = 10;
        }
        break;
      // Horizontal only layouts
      case 3: // THUMBNAIL: 3
        numOfCols = isMobile ? 5 : 20;
        break;

      case 4: // SLIDER: 4
        numOfCols = 6;
        break;

      case 5: // SLIDESHOW: 5
        break;

      case 7: // COLUMN: 7
        numOfCols = 10;
        break;

      case 9: // FULLSIZE: 9
        break;

      default:
        // pgStyles.scrollDirection = GALLERY_CONSTS.scrollDirection.VERTICAL;
        numOfCols = 5;
        numOfRows = 5;
        break;
    }
    const res = numOfRows * numOfCols;
    return res < min ? min : res > max ? max : res;
  }

  processPgItemPropsForInfoElement(infoType, pgItemProps) {
    const wrapperProps = {
      viewMode: this.galleryWrapper.siteHelper.parseViewMode(
        this.galleryWrapperProps.viewMode,
      ),
      eventsListener: this.galleryWrapper.eventHandler.handleEvent,
      infoType,
    };

    const itemLoveData = {
      ...this.galleryWrapper.state.itemsLoveData[pgItemProps.id],
    };

    return { ...pgItemProps, ...wrapperProps, ...itemLoveData };
  }

  fetchInfoElementIfNeeded() {
    if (utils.isSSR()) {
      return;
    }
    if (!this.infoElement && !this.fetchingInfoElement) {
      this.fetchingInfoElement = true;
      import(
        /* webpackChunkName: "ProGalleryInfoElement" */ '@wix/pro-gallery-info-element'
      ).then((module) => {
        this.fetchingInfoElement = false;
        const { InfoElement } = module;
        this.infoElement = InfoElement;
      });
    }
  }

  renderInfoElement(type, pgItemProps) {
    const InfoElement = this.infoElement || TextInfoElement;
    this.fetchInfoElementIfNeeded();
    return (
      <InfoElement
        {...this.processPgItemPropsForInfoElement(type, pgItemProps)}
      />
    );
  }

  hoverInfoElement = (pgItemProps) => {
    return this.renderInfoElement(EXTERNAL_INFO_TYPE.HOVER, pgItemProps);
  };

  externalInfoElement = (pgItemProps) => {
    return this.renderInfoElement(EXTERNAL_INFO_TYPE.EXTERNAL, pgItemProps);
  };

  slideshowInfoElement = (pgItemProps) => {
    return this.renderInfoElement(EXTERNAL_INFO_TYPE.SLIDESHOW, pgItemProps);
  };

  getExternalInfoRenderersIfNeeded() {
    if (
      experimentsWrapper.getExperimentBoolean(
        'specs.pro-gallery.useInfoElement-viewer',
      )
    ) {
      return {
        customHoverRenderer: this.hoverInfoElement,
        customInfoRenderer: this.externalInfoElement,
        customSlideshowInfoRenderer: this.slideshowInfoElement,
      };
    } else {
      return {};
    }
  }
}
